import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

export function getAll() {
  return axios
    .get(`${SERVER_ADDRESS}/organization/all`)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(err => console.error(err))
}

export function getRecord() {
  return axios
    .get(`${SERVER_ADDRESS}/organization/`)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(err => console.error(err))
}

export function updateRecord(id, data) {
  console.log(data, 'Service')
  return axios.put(`${SERVER_ADDRESS}/organization/${id}`, data).then(res => {
    console.log(res)
    return res.data
  })
}
