import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

export function getBalance() {
  return axios
    .get(`${SERVER_ADDRESS}/tokens/balance`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function isAccountExist() {
  return axios
    .get(`${SERVER_ADDRESS}/tokens/stripe/status`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function getPrices() {
  return axios
    .get(`${SERVER_ADDRESS}/tokens/prices`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function getCards() {
  return axios
    .get(`${SERVER_ADDRESS}/stripe/cards`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function buyTokens(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/tokens/buy-tokens`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}
