import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getList, getSelectedPlan } from 'services/plan'
import actions from './actions'

export function* GET_LIST() {
  const success = yield call(getList)
  console.log(success)
  yield put({
    type: 'plan/SET_STATE',
    payload: {
      planList: success,
    },
  })
}

export function* GET_SELECTED_PLAN() {
  const success = yield call(getSelectedPlan)
  console.log(success)
  yield put({
    type: 'plan/SET_STATE',
    payload: {
      selected: success.plan,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_SELECTED_PLAN, GET_SELECTED_PLAN),
  ])
}
