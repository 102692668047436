import { all, takeEvery, put, call } from 'redux-saga/effects'

import { getTransactions, getAllTransactions } from 'services/transactions'
import { notification } from 'antd'
import actions from './actions'

export function* GET_TRANSACTIONS({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(getTransactions, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactions: success.results,
        loading: false,
      },
    })
  }
}

export function* GET_ALL_TRANSACTIONS({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(getAllTransactions, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        transactions: success.results,
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTIONS, GET_TRANSACTIONS),
    takeEvery(actions.GET_ALL_TRANSACTIONS, GET_ALL_TRANSACTIONS),
  ])
}
