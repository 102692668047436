import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import contacts from './contacts/reducers'
import phoneNumbers from './phone/reducers'
import messages from './messages/reducers'
import keywords from './keywords/reducers'
import customer from './customer/reducers'
import plan from './plan/reducers'
import organization from './organization/reducers'
import tokens from './tokens/reducers'
import coupons from './coupons/reducers'
import transactions from './transactions/reducers'
import subscription from './subscription/reducers'
import noticification from './noticifications/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    contacts,
    phoneNumbers,
    messages,
    keywords,
    customer,
    plan,
    organization,
    tokens,
    coupons,
    transactions,
    subscription,
    noticification,
  })
