import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

// const headers = {
//   'Content-Type': 'application/x-www-form-urlencoded'
// };

export function getList() {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

  return axios.get(`${SERVER_ADDRESS}/plans/all`).then(res => {
    return res.data
  })
}

export function deleteRecord(id) {
  console.log(id, 'Service')
  return axios.delete(`${SERVER_ADDRESS}/plans/${id}`).then(res => {
    return res.data
  })
}

export function getSelectedPlan() {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

  return axios.get(`${SERVER_ADDRESS}/plans/me`).then(res => {
    return res.data
  })
}
