const moment = require('moment-timezone')

export const dateFormatter = date => {
  const zone = moment.tz.guess()
  console.log(zone)
  return moment.tz(date, zone).format('LLL')
}

export const iff = (condition, then, otherwise) => (condition ? then : otherwise)

export const formatTwoDecimalPlaces = (num, fixed) => {
  const number = parseFloat(num).toFixed(fixed)

  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const currencyDecorator = value => `$${formatTwoDecimalPlaces(value, 2)}`

const PLANS = [
  {
    name: 'Trial',
    price: '$1',
    textPrice: '$0.06',
    skipPrice: '$0.15',
    // texts: '5000 Texts (1 time)',
    phoneNumbers: 10,
    access: '/advanced/subscribe/trial',
    active: false,
  },
  {
    name: 'Basic',
    period: '/mo',
    textPrice: '$0.03',
    skipPrice: '$0.15',
    // texts: '5000 Texts/Month Included',
    phoneNumbers: 10,
    access: '/advanced/subscribe/basic',
    active: false,
  },
  {
    name: 'Professional',
    period: '/mo',
    textPrice: '$0.02',
    skipPrice: '$0.15',
    // texts: '5000 Texts/Month Included',
    phoneNumbers: 10,
    access: '/advanced/subscribe/professional',
    active: false,
  },
  {
    name: 'Master',
    period: '/mo',
    textPrice: '$0.01',
    skipPrice: '$0.15',
    // texts: '5000 Texts/Month Included',
    phoneNumbers: 10,
    access: '/advanced/subscribe/master',
    active: false,
  },
  // {
  //   name: 'Elite',
  //   period: '/mo',
  //   textPrice: '$0.003',
  //   skipPrice: '$0.15',
  //   texts: '5000 Texts/Month Included',
  //   phoneNumbers: 10,
  //   access: '/advanced/subscribe/elite',
  //   active: false,
  // },
]

export const getPlans = plans => {
  return (
    plans &&
    plans
      .filter(selected => selected.active === 'yes')
      .map(row => {
        return {
          ...PLANS.filter(plan => row.name === plan.name).map(requiredPlan => {
            return {
              ...requiredPlan,
              price: currencyDecorator(row.price),
              stripePlanId: row.plan_stripe_id,
              id: row._id,
            }
          }),
        }
      })
      .map(plan1 => {
        return plan1[0]
      })
  )
}
