import jwtDeode from 'jwt-decode'
// import firebase from 'firebase/app'
import { notification } from 'antd'
import setAuthToken from '../utils/setAuthToken'
// import 'firebase/auth'
// import 'firebase/database'
// import 'firebase/storage'
import { SET_CURRENT_USER } from './types'
import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

// const firebaseConfig = {
//   apiKey: 'AIzaSyA2LHKgdr2GQb_QUBYfhMOaxgOuGjw1z5E',
//   authDomain: 'airui-a4b63.firebaseapp.com',
//   databaseURL: 'https://airui-a4b63.firebaseio.com',
//   projectId: 'airui-a4b63',
//   storageBucket: 'airui-a4b63.appspot.com',
//   messagingSenderId: '1039460737420',
// }
// const headers = {
//   'Content-Type': 'application/x-www-form-urlencoded'
// };
// const firebaseApp = firebase.initializeApp(firebaseConfig)

// export default firebaseApp

export async function login(email, password) {
  const postData = { email, password }
  return axios
    .post(`${SERVER_ADDRESS}/users/login`, postData)
    .then(res => {
      console.log(res.data)
      const { id, userEmail, phone, role, name, token, organization, tokens, plan } = res.data
      localStorage.setItem('jwtToken', token)
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          id,
          email: userEmail,
          phone,
          role,
          plan,
          crm: plan ? plan.crm : false,
          name,
          organization,
          authorized: true,
          tokens,
        }),
      )
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwtDeode(token)
      // Set current user
      setCurrentUser(decoded)
      return token
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export async function loginAsAdmin(payload) {
  // const postData = { email, password, organization }
  return axios
    .post(`${SERVER_ADDRESS}/users/loginAsAdmin`, payload)
    .then(res => {
      const {
        id,
        userEmail,
        phone,
        role,
        name,
        token,
        organization,
        tokens,
        administrator,
      } = res.data
      localStorage.setItem('jwtToken', token)
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          id,
          email: userEmail,
          phone,
          role,
          name,
          organization,
          authorized: true,
          tokens,
          administrator,
        }),
      )
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwtDeode(token)
      // Set current user
      setCurrentUser(decoded)
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export async function forgotPassword(email) {
  const postData = { email }
  return axios
    .post(`${SERVER_ADDRESS}/users/forgotPassword`, postData)
    .then(res => {
      // notification.success({
      //   message: 'Password Reset Successful',
      //   description: 'Please check your email to get your new password',
      // })
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export async function register(
  email,
  password,
  plan,
  firstName,
  lastName,
  phone,
  organization,
  stripeId,
  couponCode,
) {
  const status = false
  const postData = {
    email,
    password,
    plan,
    firstName,
    lastName,
    phone,
    organization,
    stripeId,
    couponCode,
    status,
  }
  return axios
    .post(`${SERVER_ADDRESS}/users/register`, postData)
    .then(() => {
      return true
    })
    .catch(error => {
      return error.response
    })
}

export async function currentAccount() {
  const token = localStorage.getItem('jwtToken')
  const userInfo = localStorage.getItem('userInfo')
  return { token, userInfo: userInfo ? JSON.parse(userInfo) : {} }
  // let userLoaded = false
  // function getCurrentUser(auth) {
  //   return new Promise((resolve, reject) => {
  //     if (userLoaded) {
  //       resolve(firebaseAuth.currentUser)
  //     }
  //     const unsubscribe = auth.onAuthStateChanged(user => {
  //       userLoaded = true
  //       unsubscribe()
  //       resolve(user)
  //     }, reject)
  //   })
  // }
  // return getCurrentUser(firebaseAuth())
}

export async function logout() {
  localStorage.removeItem('userInfo')
  return localStorage.removeItem('jwtToken')
}
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  }
}

export function getList() {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  return axios.get(`${SERVER_ADDRESS}/users/all`).then(res => {
    console.log(res)
    return res.data
  })
}

export function addNewRecord(data) {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  return axios
    .post(`${SERVER_ADDRESS}/teams/register`, data)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export function deleteRecord(id) {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  console.log(id, 'Service')
  return axios.delete(`${SERVER_ADDRESS}/users/${id}`).then(res => {
    return res.data
  })
}

export function updateRecord(id, data) {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  console.log(data, 'Service')
  return axios
    .put(`${SERVER_ADDRESS}/users/${id}`, data)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}
