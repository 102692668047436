const actions = {
  SET_STATE: 'noticifications/SET_STATE',
  SEND_NOTICIFICATION: 'noticifications/SEND_NOTICIFICATION',
  GET_ALL_NOTICIFICATION: 'noticifications/GET_ALL_NOTICIFICATION',
  CLOSE_NOTIFICATION: 'noticifications/CLOSE_NOTIFICATION',
  GET_ALL_NOTICIFICATION_ADMINISTRATOR: 'noticifications/GET_ALL_NOTICIFICATION_ADMINISTRATOR',
  DELETE_NOTIFICATION: 'noticifications/DELETE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'noticifications/UPDATE_NOTIFICATION',
  SEND_DRAFTS: 'noticifications/SEND_DRAFTS',
}

export default actions
