const actions = {
  GET_LIST: 'phoneNumbers/GET_LIST',
  GET_AREA_CODES: 'phoneNumbers/GET_AREA_CODES',
  SET_STATE: 'phoneNumbers/SET_STATE',
  ADD_NEW_RECORD: 'phoneNumbers/ADD_NEW_RECORD',
  BUY_TWILIO_NUMBER: 'phoneNumbers/BUY_TWILIO_NUMBER',
  GET_TWILIO_NUMBER: 'phoneNumbers/GET_TWILIO_NUMBER',
  DELETE_RECORD: 'phoneNumbers/DELETE_RECORD',
  UPDATE_RECORD: 'phoneNumbers/UPDATE_RECORD',
}

export default actions
