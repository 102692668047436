import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

export function cancelSubscription(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/subscription/cancel`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function activeSubscription(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/subscription/cancel`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function updateSubscriptionPlan(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/subscription/update`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}
