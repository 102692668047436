import React from 'react'
import queryString from 'query-string'
import { Route, Redirect } from 'react-router-dom'

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  const query = queryString.parse(rest.location.search)

  return (
    <Route
      {...rest}
      render={props =>
        !localStorage.getItem('jwtToken') ? (
          <Component {...props} />
        ) : (
          <Redirect to={query.redirect || '/'} />
        )
      }
    />
  )
}

export default UnauthenticatedRoute
