const actions = {
  GET_CONTACTS: 'contacts/GET_CONTACTS',
  GET_STATE: 'contacts/GET_STATE',
  DELETE_STATE: 'contacts/DELETE_STATE',
  SET_STATE: 'contacts/SET_STATE',
  GET_CAMPAIGN_LIST: 'contacts/GET_CAMPAIGN_LIST',
  IMPORT_CONTACTS: 'contacts/IMPORT_CONTACTS',
  EXPORT_CONTACTS: 'contacts/EXPORT_CONTACTS',
  GET_SCHEMA_COLUMNS: 'contacts/GET_SCHEMA_COLUMNS',
  GET_COUNTS: 'contacts/GET_COUNTS',
  GET_FILTERS: 'contacts/GET_FILTERS',
  ADD_NEW_RECORD: 'contacts/ADD_NEW_RECORD',
  DELETE_RECORD: 'contacts/DELETE_RECORD',
  UPDATE_RECORD: 'contacts/UPDATE_RECORD',
  UPDATE_STATE: 'contacts/UPDATE_STATE',
  GET_IMPORTED_DATA: 'contacts/GET_IMPORTED_DATA',
  DELETE_IMPORT: 'contacts/DELETE_IMPORT',
  GET_CONTACTS_BY_GROUP: 'contacts/GET_CONTACTS_BY_GROUP',
  SEND_TO_SKIP_TRACED: 'contacts/SEND_TO_SKIP_TRACED',
  EXPORT_CONTACTS_BY_GROUP: 'contacts/EXPORT_CONTACTS_BY_GROUP',
  EXPORT_ALL_CONTACTS: 'contacts/EXPORT_ALL_CONTACTS',
}

export default actions
