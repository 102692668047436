import React from 'react'
import { Typography, Select } from 'antd'
import { connect } from 'react-redux'
import { iff, formatTwoDecimalPlaces } from 'config/commonMethods'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const { Option } = Select
const { Text } = Typography
const RECORD_LIMIT = 1000

@connect(({ tokens, user, customer, plan }) => ({ tokens, user, customer, plan }))
class TopBar extends React.Component {
  state = {
    email: 'admin@threepointzero.app',
    // load: false,
    balance: '',
  }

  componentWillMount = () => {
    const { dispatch } = this.props
    if (
      localStorage.getItem('userInfo') &&
      JSON.parse(localStorage.getItem('userInfo')).email === this.state.email
    ) {
      dispatch({
        type: 'customer/GET_LIST',
        payload: {
          page: 1,
          limit: RECORD_LIMIT,
        },
      })
    }
    if (this.props.user.role !== 'administrator') {
      dispatch({
        type: 'plan/GET_SELECTED_PLAN',
        payload: false,
      })
    }
  }

  viewAsHandler = async value => {
    const { dispatch, history } = this.props

    // this.setState({ load: true })
    const payload = {
      organization: value,
      email: this.state.email,
    }
    await dispatch({
      type: 'user/LOGIN_AS_ADMIN',
      payload,
      history,
    })

    setTimeout(() => {
      // this.setState({ load: false })
    }, 500)
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.tokens.balance !== nextProps.tokens.balance) {
      this.setState({ balance: nextProps.tokens.balance })
    }
  }

  render() {
    const { balance } = this.state
    const { list } = this.props.customer
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const { selected } = this.props.plan
    return (
      <div className={style.topbar}>
        <div className="mr-md-10 mr-auto  ">{/* <Search /> */}</div>
        <div className="mr-2">
          {this.props.user.role !== 'administrator' && (
            <Text strong>
              Balance: $
              <span style={{ color: iff(balance <= 0, 'red', 'green') }}>
                {selected && selected.token_price && formatTwoDecimalPlaces(balance / 100, 2)}
              </span>
            </Text>
          )}
        </div>
        {(userInfo.role === 'administrator' || userInfo.administrator) && (
          <div className="mr-2">
            <Select
              style={{ width: 180 }}
              placeholder="View As"
              onChange={this.viewAsHandler}
              allowClear
              defaultValue={
                localStorage.getItem('userInfo') &&
                JSON.parse(localStorage.getItem('userInfo')).organization
              }
              loading={iff(this.state.load, true, false)}
            >
              {Array.isArray(list) &&
                [...list, { organization: { name: 'Administrator' }, name: 'Administrator' }].map(
                  customer => {
                    return (
                      customer.organization && (
                        <Option value={customer.organization._id}>
                          {customer.organization.name}
                        </Option>
                      )
                    )
                  },
                )}
            </Select>
          </div>
        )}
        <div className="">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
