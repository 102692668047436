import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const AuthenticatedRoute = ({ component: Component, tokens, roles = [], ...rest }) => {
  const balance = parseInt(tokens.balance, 10)
  const { role } = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {}
  console.log('Allowed Roles:', roles)
  return (
    <Route
      {...rest}
      render={props => {
        if (
          // eslint-disable-next-line no-restricted-globals
          !isNaN(balance) &&
          balance < 0 &&
          rest.path !== '/account/buy-tokens' &&
          role !== 'administrator'
        ) {
          return <Redirect to="/account/buy-tokens" />
        }
        if (localStorage.getItem('jwtToken')) {
          if (!roles.includes(role)) {
            if (rest.path === '/dashboard/analytics') {
              return <Redirect to="/leads" />
            }
            return <Redirect to="/unauthorized" />
          }
          return <Component {...props} />
        }
        return <Redirect to={`/system/login?redirect=${props.location.pathname}`} />
      }}
    />
  )
}

export default AuthenticatedRoute
