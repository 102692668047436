import { all, takeEvery, put, call } from 'redux-saga/effects'

import { getBalance, getPrices, buyTokens, isAccountExist, getCards } from 'services/tokens'
import { notification } from 'antd'
import actions from './actions'

export function* GET_BALANCE() {
  yield put({
    type: 'tokens/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(getBalance)
  if (success.status === 400) {
    // notification.error({
    //   message: 'Error',
    //   description: success.data.message,
    // })
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        balance: 0,
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        balance: success.tokens,
        loading: false,
      },
    })
  }
}

export function* IS_ACCOUNT_EXIST({ payload }) {
  yield put({
    type: 'tokens/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(isAccountExist, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        isAccountExist: success.isAccountExist,
        loading: false,
      },
    })
  }
}

export function* GET_PRICES({ payload }) {
  yield put({
    type: 'tokens/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(getPrices, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    console.log('123456', success)
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        prices: success.results,
        loading: false,
      },
    })
  }
}

export function* GET_CARD_DETAILS({ payload }) {
  yield put({
    type: 'tokens/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(getCards, payload)
  if (success.status === 400) {
    //   notification.error({
    //     message: 'Error',
    //     description: success.data.message,
    //   })
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    console.log('123456', success)
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        cards: success.cards,
        loading: false,
      },
    })
  }
}

export function* BUY_TOKENS({ payload }) {
  yield put({
    type: 'tokens/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(buyTokens, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'tokens/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Payment Successful.',
      description: success.message,
    })
    yield put({
      type: 'tokens/GET_BALANCE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'tokens/IS_ACCOUNT_EXIST',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'tokens/GET_CARD_DETAILS',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_BALANCE, GET_BALANCE),
    takeEvery(actions.GET_PRICES, GET_PRICES),
    takeEvery(actions.BUY_TOKENS, BUY_TOKENS),
    takeEvery(actions.IS_ACCOUNT_EXIST, IS_ACCOUNT_EXIST),
    takeEvery(actions.GET_CARD_DETAILS, GET_CARD_DETAILS),
    GET_BALANCE(),
  ])
}
