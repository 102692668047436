/* eslint-disable no-nested-ternary */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import UnauthenticatedRoute from 'components/UnauthenticatedRoute'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
// import AdministratorRoute from 'components/AdministratorRoute'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Schedules
  {
    path: '/schedules/create',
    Component: loadable(() => import('pages/schedules/create-schedule')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/schedules/update',
    Component: loadable(() => import('pages/schedules/update-schedule')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/schedules/list',
    Component: loadable(() => import('pages/schedules/schedules-list')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/schedules/messaging',
    Component: loadable(() => import('pages/schedules/messaging')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager', 'qualifications'],
  },
  {
    path: '/schedules/deletedConversation',
    Component: loadable(() => import('pages/schedules/deletedConversation')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager', 'qualifications'],
  },
  {
    path: '/schedules/campaign-builder',
    Component: loadable(() => import('pages/schedules/campaign-builder')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  // Dashboards
  {
    path: '/dashboard/analytics',
    Component: loadable(() => import('pages/dashboard/analytics')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/dashboard/statistics',
    Component: loadable(() => import('pages/dashboard/statistics')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/dashboard/ecommerce',
    Component: loadable(() => import('pages/dashboard/ecommerce')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/dashboard/crypto',
    Component: loadable(() => import('pages/dashboard/crypto')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/dashboard/jira',
    Component: loadable(() => import('pages/dashboard/jira')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/dashboard/helpdesk',
    Component: loadable(() => import('pages/dashboard/helpdesk')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Ecommerce
  {
    path: '/ecommerce/dashboard',
    Component: loadable(() => import('pages/ecommerce/dashboard')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/ecommerce/orders',
    Component: loadable(() => import('pages/ecommerce/orders')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/ecommerce/product-catalog',
    Component: loadable(() => import('pages/ecommerce/product-catalog')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/ecommerce/product-details',
    Component: loadable(() => import('pages/ecommerce/product-details')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/ecommerce/cart',
    Component: loadable(() => import('pages/ecommerce/cart')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Apps
  {
    path: '/apps/messaging',
    Component: loadable(() => import('pages/apps/messaging')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/apps/calendar',
    Component: loadable(() => import('pages/apps/calendar')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/apps/mail',
    Component: loadable(() => import('pages/apps/mail')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/apps/profile',
    Component: loadable(() => import('pages/apps/profile')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/apps/gallery',
    Component: loadable(() => import('pages/apps/gallery')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Extra Apps
  {
    path: '/extra-apps/github-explore',
    Component: loadable(() => import('pages/extra-apps/github-explore')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/github-discuss',
    Component: loadable(() => import('pages/extra-apps/github-discuss')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/digitalocean-droplets',
    Component: loadable(() => import('pages/extra-apps/digitalocean-droplets')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/digitalocean-create',
    Component: loadable(() => import('pages/extra-apps/digitalocean-create')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/google-analytics',
    Component: loadable(() => import('pages/extra-apps/google-analytics')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/wordpress-post',
    Component: loadable(() => import('pages/extra-apps/wordpress-post')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/wordpress-posts',
    Component: loadable(() => import('pages/extra-apps/wordpress-posts')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/wordpress-add',
    Component: loadable(() => import('pages/extra-apps/wordpress-add')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/todoist-list',
    Component: loadable(() => import('pages/extra-apps/todoist-list')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/jira-dashboard',
    Component: loadable(() => import('pages/extra-apps/jira-dashboard')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/jira-agile-board',
    Component: loadable(() => import('pages/extra-apps/jira-agile-board')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/extra-apps/helpdesk-dashboard',
    Component: loadable(() => import('pages/extra-apps/helpdesk-dashboard')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Widgets
  {
    path: '/widgets/general',
    Component: loadable(() => import('pages/widgets/general')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/widgets/lists',
    Component: loadable(() => import('pages/widgets/lists')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/widgets/tables',
    Component: loadable(() => import('pages/widgets/tables')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/widgets/charts',
    Component: loadable(() => import('pages/widgets/charts')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Cards
  {
    path: '/cards/basic-cards',
    Component: loadable(() => import('pages/cards/basic-cards')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/cards/tabbed-cards',
    Component: loadable(() => import('pages/cards/tabbed-cards')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // UI Kits
  {
    path: '/ui-kits/bootstrap',
    Component: loadable(() => import('pages/ui-kits/bootstrap')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/ui-kits/antd',
    Component: loadable(() => import('pages/ui-kits/antd')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Tables
  {
    path: '/tables/bootstrap',
    Component: loadable(() => import('pages/tables/bootstrap')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/tables/antd',
    Component: loadable(() => import('pages/tables/antd')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Contacts
  {
    path: '/contacts',
    Component: loadable(() => import('pages/contacts')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/contacts/dnc',
    Component: loadable(() => import('pages/contacts/dnc_page')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin'],
  },
  // CRM
  {
    path: '/leads',
    Component: loadable(() => import('pages/contacts/leads_page')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/leads/:status',
    Component: loadable(() => import('pages/contacts/leads_page')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
  },
  {
    path: '/agencies',
    Component: loadable(() => import('pages/agencies')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/buyers',
    Component: loadable(() => import('pages/buyers')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },

  {
    path: '/contacts/list',
    exact: true,
    Component: loadable(() => import('pages/contacts')),
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/contacts/list/:groupId',
    exact: true,
    Component: loadable(() => import('pages/contacts')),
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/contacts/add',
    Component: loadable(() => import('pages/contacts/add')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/contacts/import',
    Component: loadable(() => import('pages/contacts/import')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Charts
  {
    path: '/charts/chartistjs',
    Component: loadable(() => import('pages/charts/chartistjs')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/charts/chartjs',
    Component: loadable(() => import('pages/charts/chartjs')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/charts/c3',
    Component: loadable(() => import('pages/charts/c3')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Icons
  {
    path: '/icons/feather-icons',
    Component: loadable(() => import('pages/icons/feather-icons')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/icons/fontawesome',
    Component: loadable(() => import('pages/icons/fontawesome')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/icons/linearicons-free',
    Component: loadable(() => import('pages/icons/linearicons-free')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/icons/icomoon-free',
    Component: loadable(() => import('pages/icons/icomoon-free')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Advanced
  {
    path: '/advanced/form-examples',
    Component: loadable(() => import('pages/advanced/form-examples')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/advanced/email-templates',
    Component: loadable(() => import('pages/advanced/email-templates')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/advanced/utilities',
    Component: loadable(() => import('pages/advanced/utilities')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/advanced/grid',
    Component: loadable(() => import('pages/advanced/grid')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/advanced/typography',
    Component: loadable(() => import('pages/advanced/typography')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },

  {
    path: '/advanced/invoice',
    Component: loadable(() => import('pages/advanced/invoice')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
    protected: false,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
    protected: false,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/system/register')),
    exact: true,
    protected: false,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/phone_numbers',
    Component: loadable(() => import('pages/phone')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin'],
  },
  {
    path: '/phone_numbers/add',
    Component: loadable(() => import('pages/phone/add')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/messages',
    Component: loadable(() => import('pages/messages')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/keywords',
    Component: loadable(() => import('pages/keywords')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/team',
    Component: loadable(() => import('pages/users')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/edit-profile',
    Component: loadable(() => import('pages/users/edit-profile')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  {
    path: '/customers',
    Component: loadable(() => import('pages/customers')),
    exact: true,
    protected: true,
    roles: ['administrator'],
  },
  {
    path: '/bw-organizations',
    Component: loadable(() => import('pages/Admin/BWOrganizations')),
    exact: true,
    protected: true,
    roles: ['administrator'],
  },
  {
    path: '/transactions',
    Component: loadable(() => import('pages/Admin/Transactions')),
    exact: true,
    protected: true,
    roles: ['administrator'],
  },
  {
    path: '/coupons',
    Component: loadable(() => import('pages/coupons')),
    exact: true,
    protected: true,
    roles: ['administrator'],
  },
  {
    path: '/notifications',
    Component: loadable(() => import('pages/Admin/Noticifications/List')),
    exact: true,
    protected: true,
    roles: ['administrator'],
  },
  {
    path: '/blocked-keywords',
    Component: loadable(() => import('pages/Admin/BlockedKeywords')),
    exact: true,
    protected: true,
    roles: ['administrator'],
  },
  {
    path: '/contacts/imports',
    Component: loadable(() => import('pages/imports')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  // Account
  {
    path: '/account',
    Component: loadable(() => import('pages/account')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin'],
  },
  {
    path: '/account/buy-tokens',
    Component: loadable(() => import('pages/buy-tokens/buy-tokens')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin', 'manager'],
  },
  {
    path: '/account/transactions',
    Component: loadable(() => import('pages/transactions')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin'],
  },
  {
    path: '/account/plans',
    Component: loadable(() => import('pages/account/SubscriptionPlans/SubscriptionPlans')),
    exact: true,
    protected: true,
    roles: ['administrator', 'super admin'],
  },
  {
    path: '/contracts',
    Component: loadable(() => import('pages/offers')),
    exact: true,
    protected: true,
    roles: [
      'administrator',
      'super admin',
      'manager',
      'acquisitions',
      'dispositions',
      'qualifications',
    ],
  },
  // Un protected
  {
    path: '/system/month',
    Component: loadable(() => import('pages/SubscriptionPlans')),
    exact: true,
    protected: false,
  },
  {
    path: '/system/monthplus',
    Component: loadable(() => import('pages/SubscriptionPlans')),
    exact: true,
    protected: false,
  },
  {
    path: '/system/yearly',
    Component: loadable(() => import('pages/SubscriptionPlans')),
    exact: true,
    protected: false,
  },
  {
    path: '/system/yearlyplus',
    Component: loadable(() => import('pages/SubscriptionPlans')),
    exact: true,
    protected: false,
  },
  {
    path: '/advanced/plans',
    Component: loadable(() => import('pages/advanced/pricing-tables')),
    exact: true,
    protected: false,
  },
  {
    path: '/advanced/subscribe/:plan',
    Component: loadable(() =>
      import('pages/advanced/pricing-tables/SubcriptionSelectPage/SubscriptionSelectPage'),
    ),
    exact: true,
    protected: false,
  },
]

const mapStateToProps = ({ settings, tokens }) => ({ settings, tokens })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
      tokens,
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route
              exact
              path="/system/login"
              component={loadable(() => import('pages/system/login'))}
            />
            <Route
              exact
              path="/unauthorized"
              component={loadable(() => import('pages/system/403'))}
            />
            <Route exact path="/" render={() => <Redirect to="/dashboard/analytics" />} />
            {routes.map(({ path, Component, exact, protected: isProtected, roles }) =>
              isProtected ? (
                <AuthenticatedRoute
                  path={path}
                  key={path}
                  exact={exact}
                  tokens={tokens}
                  history={history}
                  component={Component}
                  roles={roles}
                />
              ) : (
                <UnauthenticatedRoute
                  path={path}
                  key={path}
                  exact={exact}
                  history={history}
                  component={Component}
                />
              ),
            )}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
