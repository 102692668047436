import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import {
  login,
  register,
  currentAccount,
  logout,
  getList,
  addNewRecord,
  deleteRecord,
  updateRecord,
  forgotPassword,
  loginAsAdmin,
} from 'services/user'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  })
  if (success) {
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in. Welcome Back!',
    })
  }
}

export function* LOGIN_AS_ADMIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(loginAsAdmin, payload)
  // yield put({
  //   type: 'user/LOAD_CURRENT_ACCOUNT',
  // })
  if (success) {
    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in as user!',
    // })

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'tokens/GET_BALANCE',
    })
    yield put({
      type: 'menu/GET_DATA',
      payload: success.role,
    })
    setTimeout(() => {
      // history.push('/dashboard/analytics')
      window.location.href = '/dashboard/analytics'
    }, 100)
  }
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(forgotPassword, email)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
  if (success) {
    yield history.push('/system/login')
    notification.success({
      message: 'Password Reset Successful',
      description: 'Please check your email to get your new password',
    })
  }
}

export function* REGISTER({ payload }) {
  const {
    email,
    password,
    plan,
    firstName,
    lastName,
    phone,
    organization,
    stripeId,
    couponCode,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(
    register,
    email,
    password,
    plan,
    firstName,
    lastName,
    phone,
    organization,
    stripeId,
    couponCode,
  )
  if (success) {
    if (success.status) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      console.log(success)
      return notification.warning({
        message: success.code || success.status,
        description: success.data.error ? success.data.error : success.data,
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push('/system/login')
    notification.success({
      message: 'Register was succeed',
      description: 'You have successfully registered. You can now login.',
    })
  }
}

export function* REGISTER_SUBSCRIPTION({ payload }) {
  const {
    email,
    password,
    plan,
    firstName,
    lastName,
    phone,
    organization,
    stripeId,
    couponCode,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(
    register,
    email,
    password,
    plan,
    firstName,
    lastName,
    phone,
    organization,
    stripeId,
    couponCode,
  )
  if (success) {
    if (success.status) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      return notification.warning({
        message: success.code || success.status,
        description: success.data.error ? success.data.error : success.data,
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        subscribed: true,
      },
    })
    // notification.success({
    //   message: 'Register was succeed',
    //   description: 'You have successfully registered. You can now login.',
    // })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    console.log('get current account state')
    console.log(response)
    const { userInfo } = response

    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: userInfo.id || '',
        name: userInfo.name || '',
        email: userInfo.email || '',
        avatar: '',
        role: userInfo.role || '',
        tokens: userInfo.tokens || '',
        authorized: userInfo.authorized || false,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
  yield put({
    type: 'tokens/GET_BALANCE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData: [],
    },
  })
}

export function* GET_LIST() {
  const success = yield call(getList)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      list: success,
    },
  })
}

export function* ADD_NEW_RECORD({ payload }) {
  const success = yield call(addNewRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Added',
      description: 'You have successfully added the new record.',
    })
    yield put({
      type: 'user/GET_LIST',
    })
  }
}

export function* UPDATE_RECORD({ payload }) {
  const { id, data } = payload
  const success = yield call(updateRecord, id, data)
  if (success) {
    notification.success({
      message: 'Successfully Updated',
      description: 'You have successfully updated the  record.',
    })
    yield put({
      type: 'user/GET_LIST',
    })
  }
}

export function* DELETE_RECORD({ payload }) {
  const success = yield call(deleteRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Deleted',
      description: 'You have successfully deleted the  record.',
    })
  }
  yield put({
    type: 'user/GET_LIST',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.ADD_NEW_RECORD, ADD_NEW_RECORD),
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.DELETE_RECORD, DELETE_RECORD),
    takeEvery(actions.UPDATE_RECORD, UPDATE_RECORD),
    takeEvery(actions.LOGIN_AS_ADMIN, LOGIN_AS_ADMIN),
    takeEvery(actions.REGISTER_SUBSCRIPTION, REGISTER_SUBSCRIPTION),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
