import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { getRecord, updateRecord, getAll } from 'services/organization'
import actions from './actions'

export function* GET_ALL() {
  const success = yield call(getAll)
  yield put({
    type: 'organization/SET_STATE',
    payload: {
      list: success,
    },
  })
}

export function* GET_ITEM() {
  const success = yield call(getRecord)
  yield put({
    type: 'organization/SET_STATE',
    payload: {
      item: success,
    },
  })
}

export function* UPDATE_RECORD({ payload }) {
  const { id, data } = payload
  const success = yield call(updateRecord, id, data)
  if (success) {
    notification.success({
      message: 'Successfully Updated',
      description: 'You have successfully updated the  record.',
    })
  }
  yield put({
    type: 'organization/GET_ITEM',
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.GET_ITEM, GET_ITEM),
    takeEvery(actions.UPDATE_RECORD, UPDATE_RECORD),
  ])
}
