import { notification } from 'antd'
import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default
// const fs = require('fs');
const fileDownload = require('js-file-download')

// const headers = {
//   'Content-Type': 'application/x-www-form-urlencoded'
// };
axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

export function getContacts(page, limit, filters, groupId) {
  const requestUrl = !filters
    ? `${SERVER_ADDRESS}/contacts/list?page=${page}&limit=${limit}&groupId=${groupId}`
    : `${SERVER_ADDRESS}/contacts/list?page=${page}&limit=${limit}&groupId=${groupId}&filters=${encodeURIComponent(
        JSON.stringify(filters),
      )}`
  return axios.get(requestUrl).then(res => {
    return res.data
  })
}

export function getCampaignList() {
  return axios.get(`${SERVER_ADDRESS}/campaigns/list`).then(res => {
    return res.data
  })
}

export function getImportedData() {
  return axios.get(`${SERVER_ADDRESS}/contacts/getImportsByGroup`).then(res => {
    return res.data
  })
}

export const importContacts = postData => {
  return axios.post(`${SERVER_ADDRESS}/contacts/upload`, postData).then(res => {
    return res.data
  })
}

export function sendToSkipTraced(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/sendToSkip/${payload.groupId}`, { ...payload })
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
      // console.log('@@@@', error.response)
      // notification.error({
      //   message: 'Error',
      //   description: error.response.data.message,
      // })
    })
}

export function exportContactsByGroup(groupId) {
  console.log(groupId)
  return axios
    .get(`${SERVER_ADDRESS}/contacts/exportContactsByGroup/${groupId}`, {})
    .then(response => {
      if (response.data && response.data.length) {
        // const headers = Object.keys(response.data[0])
        response.data.map(contact => delete contact.skippedDate)
        // const headers = Object.keys(
        //   response.data.sort((a, b) => Object.entries(b).length - Object.entries(a).length)[0],
        // )
        const headers = Object.keys(response.data[0])
        // delete headers[0]

        response.data.unshift([...headers])
        const csv = response.data
          .map(d => {
            delete d._id
            const exportedData = Object.values(d)

            console.log(exportedData)
            // if (key === 0) {
            //   const headers = Object.keys(d);
            //   exportedData.unshift(headers);
            //
            // }
            // console.log(exportedData);
            return JSON.stringify(exportedData)
          })
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '')
        // console.log('CSV', csv)
        fileDownload(
          csv,
          `contact_${Math.random()
            .toString(36)
            .substring(7)}.csv`,
        )
      } else {
        notification.warning({
          message: 'Failed Export',
          description: 'No data found for this export',
        })
      }
    })
}

export async function exportContacts(postData) {
  const {
    payload: { campaign, propertyState, propertyCity, type },
  } = postData
  const postObj = {}
  if (campaign) {
    postObj.campaign = campaign
  }
  if (propertyState) {
    postObj.propertyState = propertyState
  }
  if (type) {
    postObj.type = type
  }
  if (propertyCity) {
    postObj.propertyCity = propertyCity
  }
  return axios({
    method: 'post',
    url: `${SERVER_ADDRESS}/contacts/export`,
    data: { ...postObj },
    // responseType: 'stream'
  }).then(async response => {
    if (response.data && response.data.length) {
      response.data.map(contact => delete contact.skippedDate)

      const headers = Object.keys(response.data[0])

      response.data.unshift([...headers])
      const csv = response.data
        .map(d => {
          delete d._id
          const exportedData = Object.values(d)

          console.log(exportedData)

          return JSON.stringify(exportedData)
        })
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')
      fileDownload(
        csv,
        `contact_${Math.random()
          .toString(36)
          .substring(7)}.csv`,
      )
    } else {
      notification.warning({
        message: 'Failed Export',
        description: 'No data found for this export',
      })
    }
  })
}

export function getSchema() {
  return axios.get(`${SERVER_ADDRESS}/contacts/getSchema`).then(res => {
    return res.data
  })
}
export function getCounts() {
  return axios.get(`${SERVER_ADDRESS}/contacts/getCounts`).then(res => {
    return res.data
  })
}
export function getFilters() {
  return axios.get(`${SERVER_ADDRESS}/contacts/getFilters`).then(res => {
    return res.data
  })
}

export function deleteRecord(id) {
  return axios.delete(`${SERVER_ADDRESS}/contacts/${id}`).then(res => {
    return res.data
  })
}

export function deleteImport(id) {
  return axios.delete(`${SERVER_ADDRESS}/contacts/deleteImports/${id}`).then(res => {
    return res.data
  })
}

export function getContactsByGroup(payload) {
  const requestUrl = !payload.filters
    ? `${SERVER_ADDRESS}/contacts/getContactsByGroup/${payload.id}?page=${payload.page}&limit=${payload.limit}`
    : `${SERVER_ADDRESS}/contacts/getContactsByGroup/${payload.id}?page=${payload.page}&limit=${
        payload.limit
      }&filters=${encodeURIComponent(JSON.stringify(payload.filters))}`
  return axios.get(requestUrl).then(res => {
    return res.data
  })
}

export function addNewRecord(data) {
  return axios
    .post(`${SERVER_ADDRESS}/contacts/`, data)
    .then(res => {
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export function updateRecord(id, data) {
  return axios
    .put(`${SERVER_ADDRESS}/contacts/${id}`, data)
    .then(res => {
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export function exportAllContacts(groupId) {
  console.log(groupId)
  return axios.get(`${SERVER_ADDRESS}/contacts/exportAllContacts`, {}).then(response => {
    if (response.data && response.data.length) {
      response.data.map(contact => delete contact.skippedDate)

      const headers = Object.keys(response.data[0])

      response.data.unshift([...headers])
      const csv = response.data
        .map(d => {
          delete d._id
          const exportedData = Object.values(d)

          console.log(exportedData)

          return JSON.stringify(exportedData)
        })
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')
      fileDownload(
        csv,
        `contact_${Math.random()
          .toString(36)
          .substring(7)}.csv`,
      )
    } else {
      notification.warning({
        message: 'Failed Export',
        description: 'No data found for this export',
      })
    }
  })
}
