import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

export function getCoupons() {
  return axios
    .get(`${SERVER_ADDRESS}/coupon`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

// validateCoupon
export function validateCoupon(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/coupon/validateCoupon`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function createCoupon(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/coupon/create`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function deleteCoupon(payload) {
  return axios
    .delete(`${SERVER_ADDRESS}/coupon/delete/${payload}`, payload)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function updateCoupon(payload) {
  return axios
    .post(`${SERVER_ADDRESS}/coupon/update/${payload.id}`, payload.data)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}
