import actions from './actions'

const initialState = {
  item: {},
  list: [],
  loading: false,
}

export default function organizationReducer(state = initialState, action) {
  console.log(action, 'Here')
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
