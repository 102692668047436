import actions from './actions'

const initialState = {
  balance: '',
  prices: [],
  cards: [],
  isAccountExist: false,
  loading: false,
}

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
