import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { getList, addNewRecord, deleteRecord, updateRecord } from 'services/customer'
import actions from './actions'

export function* GET_LIST(data) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const {
    payload: { page, limit, filters },
  } = data

  const success = yield call(getList, page, limit, filters)
  console.log(success)
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      list: success.results,
      loading: false,
      meta: {
        page,
        pageSize: 100,
        total: success.countObj.customerCount,
        pageTotal: Math.ceil(success.countObj.customerCount / 100),
      },
    },
  })
}

export function* ADD_NEW_RECORD({ payload }) {
  const success = yield call(addNewRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Added',
      description: 'You have successfully added the new record.',
    })
    yield put({
      type: 'customer/GET_LIST',
    })
  }
}

export function* UPDATE_RECORD({ payload }) {
  const { id, data } = payload
  const success = yield call(updateRecord, id, data)
  if (success) {
    notification.success({
      message: 'Successfully Updated',
      description: 'You have successfully updated the  record.',
    })
    yield put({
      type: 'customer/GET_LIST',
      payload: {
        page: 1,
        limit: 100,
      },
    })
  }
}

export function* DELETE_RECORD({ payload }) {
  const success = yield call(deleteRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Deleted',
      description: 'You have successfully deleted the  record.',
    })
  }
  yield put({
    type: 'customer/GET_LIST',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_NEW_RECORD, ADD_NEW_RECORD),
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.DELETE_RECORD, DELETE_RECORD),
    takeEvery(actions.UPDATE_RECORD, UPDATE_RECORD),
  ])
}
