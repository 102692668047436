import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
import { cancelSubscription, updateSubscriptionPlan } from 'services/subscription'
import actions from './actions'

const key = 'updatable'

export function* CANCEL_SUBSCRIPTION({ payload }) {
  yield put({
    type: 'subscription/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(cancelSubscription, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'subscription/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Success',
      description: 'Subscription successfully cancelled.',
    })
    yield put({
      type: 'subscription/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_SUBSCRIPTION({ payload }) {
  message.loading({ content: 'Loading...', key })
  yield put({
    type: 'subscription/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(updateSubscriptionPlan, payload)
  if (success.status) {
    message.error({ content: success.data.message, key, duration: 2 })
    yield put({
      type: 'subscription/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    message.success({ content: 'Subscription successfully updated.', key, duration: 2 })
    yield put({
      type: 'subscription/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'tokens/GET_BALANCE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'plan/GET_SELECTED_PLAN',
      payload: false,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION),
    takeEvery(actions.UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION),
  ])
}
