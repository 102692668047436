const actions = {
  SET_STATE: 'tokens/SET_STATE',
  GET_BALANCE: 'tokens/GET_BALANCE',
  GET_PRICES: 'tokens/GET_PRICES',
  BUY_TOKENS: 'tokens/BUY_TOKENS',
  IS_ACCOUNT_EXIST: 'tokens/IS_ACCOUNT_EXIST',
  GET_CARD_DETAILS: 'tokens/GET_CARD_DETAILS',
}

export default actions
