import actions from './actions'

const INDEX_PAGE_SIZE_DEFAULT = 1000

const initialState = {
  list: false,
  loading: false,
  meta: {
    page: 0,
    pageSize: INDEX_PAGE_SIZE_DEFAULT,
    pageTotal: 1,
    total: 0,
  },
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
