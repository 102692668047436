import { all } from 'redux-saga/effects'
import user from './user/sagas'
import contacts from './contacts/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import phoneNumbers from './phone/sagas'
import keywords from './keywords/sagas'
import messages from './messages/sagas'
import customer from './customer/sagas'
import plan from './plan/sagas'
import organization from './organization/sagas'
import tokens from './tokens/sagas'
import coupons from './coupons/sagas'
import transactions from './transactions/sagas'
import subscription from './subscription/sagas'
import noticifications from './noticifications/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    contacts(),
    phoneNumbers(),
    messages(),
    keywords(),
    customer(),
    plan(),
    organization(),
    tokens(),
    coupons(),
    transactions(),
    subscription(),
    noticifications(),
  ])
}
