import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

export function getAllNotifications() {
  return axios
    .get(`${SERVER_ADDRESS}/noticifications/all`, {})
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}

export function getAll() {
  return axios
    .get(`${SERVER_ADDRESS}/noticifications`, {})
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}

export function close(data) {
  return axios
    .post(`${SERVER_ADDRESS}/noticifications/close`, data)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function send(data) {
  return axios
    .post(`${SERVER_ADDRESS}/noticifications/send`, data)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(err => err.response)
}

export function deleteNotifications(data) {
  return axios
    .delete(`${SERVER_ADDRESS}/noticifications/delete/${data}`)
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}

export function updateNotifications(data) {
  return axios
    .put(`${SERVER_ADDRESS}/noticifications/update/${data.id}`, data.data)
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}

export function sendDrafts(id) {
  return axios
    .post(`${SERVER_ADDRESS}/noticifications/draft/${id}`)
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}
