const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  GET_LIST: 'user/GET_LIST',
  ADD_NEW_RECORD: 'user/ADD_NEW_RECORD',
  DELETE_RECORD: 'user/DELETE_RECORD',
  UPDATE_RECORD: 'user/UPDATE_RECORD',
  LOGIN_AS_ADMIN: 'user/LOGIN_AS_ADMIN',
  REGISTER_SUBSCRIPTION: 'user/REGISTER_SUBSCRIPTION',
}

export default actions
