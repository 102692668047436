import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import socketIO from 'socket.io-client'
import { SERVER_ADDRESS } from 'config/constants'
import styles from './style.module.scss'

@connect(({ user, noticification }) => ({ user, noticification }))
class ProfileMenu extends React.Component {
  state = {
    count: '',
  }

  componentWillMount = () => {
    const { dispatch, user } = this.props
    if (user.role !== 'administrator') {
      dispatch({
        type: 'noticifications/GET_ALL_NOTICIFICATION',
        payload: false,
      })
    }
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  componentDidMount = async () => {
    const { user } = this.props
    const userInfo = await JSON.parse(localStorage.getItem('userInfo'))

    if (user.role !== 'administrator') {
      const socket = socketIO(`${SERVER_ADDRESS}/noticifications`, {
        transports: ['websocket'],
        query: { organization: userInfo.organization },
      })

      socket.on('new_notice', data => {
        this.getNoticifications(data)
      })
    }
  }

  getNoticifications = data => {
    const { dispatch, user } = this.props

    this.setState({ count: data.total })
    if (user.role !== 'administrator') {
      dispatch({
        type: 'noticifications/GET_ALL_NOTICIFICATION',
        payload: false,
      })
    }
  }

  render() {
    const { user } = this.props
    const { count } = this.state
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>Hello, {user.name || 'Anonymous'}</strong>
          <div>
            <strong>Role: </strong>
            {user.role}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>Email: </strong>
            {user.email}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to="/edit-profile">
            <i className={`${styles.menuIcon} fe fe-user`} />
            Edit Profile
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          {user.role !== 'administrator' ? (
            <Badge count={count}>
              <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
            </Badge>
          ) : (
            <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
          )}
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
