import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  send,
  getAll,
  close,
  getAllNotifications,
  deleteNotifications,
  updateNotifications,
  sendDrafts,
} from 'services/noticifications'
import { notification } from 'antd'
import actions from './actions'

export function* GET_ALL_NOTICIFICATION_ADMINISTRATOR() {
  const success = yield call(getAllNotifications)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
  } else {
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        all: success,
      },
    })
  }
}

export function* GET_ALL_NOTICIFICATION() {
  const success = yield call(getAll)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
  } else {
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        list: success,
      },
    })
  }
}

export function* SEND_NOTICIFICATION({ payload }) {
  yield put({
    type: 'noticifications/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(send, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    if (payload.draft) {
      notification.success({
        message: 'Draft saved.',
        description: success.message,
      })
      return yield put({
        type: 'noticifications/GET_ALL_NOTICIFICATION_ADMINISTRATOR',
        payload: {
          loading: true,
        },
      })
    }
    notification.success({
      message: 'Notification successfully sent.',
      description: success.message,
    })

    yield put({
      type: 'noticifications/GET_ALL_NOTICIFICATION_ADMINISTRATOR',
      payload: {
        loading: true,
      },
    })
  }
}

export function* CLOSE_NOTIFICATION({ payload }) {
  yield put({
    type: 'noticifications/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(close, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Notification successfully closed.',
      description: success.message,
    })
  }
}

export function* SEND_DRAFTS({ payload }) {
  yield put({
    type: 'noticifications/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(sendDrafts, payload)

  console.log(success)
  if (success.status) {
    console.log(success)
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Notification successfully sent.',
      description: success.message,
    })

    yield put({
      type: 'noticifications/GET_ALL_NOTICIFICATION_ADMINISTRATOR',
      payload: {
        loading: true,
      },
    })
  }
}

export function* DELETE_NOTIFICATION({ payload }) {
  yield put({
    type: 'noticifications/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(deleteNotifications, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Notification successfully deleted.',
      description: success.message,
    })

    yield put({
      type: 'noticifications/GET_ALL_NOTICIFICATION_ADMINISTRATOR',
      payload: {
        loading: true,
      },
    })
  }
}

export function* UPDATE_NOTIFICATION({ payload }) {
  yield put({
    type: 'noticifications/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(updateNotifications, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'noticifications/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Notification successfully updated.',
      description: success.message,
    })

    yield put({
      type: 'noticifications/GET_ALL_NOTICIFICATION_ADMINISTRATOR',
      payload: {
        loading: true,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_NOTICIFICATION, SEND_NOTICIFICATION),
    takeEvery(actions.GET_ALL_NOTICIFICATION, GET_ALL_NOTICIFICATION),
    takeEvery(actions.CLOSE_NOTIFICATION, CLOSE_NOTIFICATION),
    takeEvery(actions.GET_ALL_NOTICIFICATION_ADMINISTRATOR, GET_ALL_NOTICIFICATION_ADMINISTRATOR),
    takeEvery(actions.DELETE_NOTIFICATION, DELETE_NOTIFICATION),
    takeEvery(actions.UPDATE_NOTIFICATION, UPDATE_NOTIFICATION),
    takeEvery(actions.SEND_DRAFTS, SEND_DRAFTS),
  ])
}
