export default async function getMenuData(role) {
  if (role.payload === 'administrator') {
    return [
      {
        title: 'Customers',
        key: 'customers',
        icon: 'fe fe-users',
        url: '/customers',
      },
      {
        title: 'Manage Phone Numbers',
        key: 'phone-numbers',
        icon: 'fe fe-phone',
        url: '/phone_numbers',
      },
      // bw-organizations
      {
        title: 'BW Organizations',
        key: 'bw-organizations',
        icon: 'fe fe-star',
        url: '/bw-organizations',
      },
      {
        title: 'Coupons',
        key: 'coupons',
        icon: 'fe fe-star',
        url: '/coupons',
      },
      {
        title: 'Blocked Keywords',
        key: 'blocked-keywords',
        icon: 'fe fe-star',
        url: '/blocked-keywords',
      },
      {
        title: 'Transactions',
        key: 'transactions',
        icon: 'fe fe-credit-card',
        url: '/transactions',
      },
      {
        title: 'Contacts',
        key: 'contacts',
        icon: 'fe fe-users',
        // url: '/contacts/list',
        children: [
          {
            title: 'DNC Contacts',
            key: 'dnc_contacts',
            url: '/contacts/dnc',
          },
        ],
      },
      {
        title: 'Notifications',
        key: 'Notifications',
        icon: 'fe fe-mail',
        url: '/notifications',
      },
    ]
  }

  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {}

  const menus = {
    'super admin': [
      {
        title: 'Dashboard',
        key: 'dashboardAnalytics',
        icon: 'fe fe-home',
        url: '/dashboard/analytics',
        roles: ['administrator', 'super admin', 'manager'],
      },
      {
        title: 'SMS',
        key: 'Schedules',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'Conversations',
            key: 'messaging',
            url: '/schedules/messaging',
            roles: ['administrator', 'super admin', 'manager', 'qualifications'],
          },
          {
            title: 'Deleted Conversation',
            key: 'deletedConversation',
            url: '/schedules/deletedConversation',
            roles: ['administrator', 'super admin', 'manager', 'qualifications'],
          },
          {
            title: 'Campaigns',
            key: 'schedulesList',
            url: '/schedules/list',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'List Builder',
            key: 'campaign-builder',
            url: '/schedules/campaign-builder',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Schedule Campaign',
            key: 'createSchedule',
            url: '/schedules/create',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Messages',
            key: 'messages',
            url: '/messages',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Phone Numbers',
            key: 'phone-numbers',
            url: '/phone_numbers',
            roles: ['administrator', 'super admin'],
          },
          {
            title: 'Keywords',
            key: 'keywords',
            url: '/keywords',
            roles: ['administrator', 'super admin', 'manager'],
          },
        ],
      },
      // CRM
      userInfo.crm
        ? {
            title: 'CRM',
            key: 'srm',
            icon: 'fe fe-message-square',
            children: [
              {
                title: 'All Leads',
                key: 'leads_contacts',
                url: '/leads',
                roles: [
                  'administrator',
                  'super admin',
                  'manager',
                  'acquisitions',
                  'dispositions',
                  'qualifications',
                ],
              },
              {
                title: 'Assigned Leads',
                key: 'AssignedLeads',
                url: '/leads/Assigned',
              },
              {
                title: 'Follow Up Leads',
                key: 'FollowUpLeads',
                url: '/leads/Follow Up',
                roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
              },
              {
                title: 'Offer Leads',
                key: 'OfferLeads',
                url: '/leads/Offer',
              },
              {
                title: 'Disposition Leads',
                key: 'DispositionLeads',
                url: '/leads/Disposition',
                roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
              },
              {
                title: 'Closed Leads',
                key: 'ClosedLeads',
                url: '/leads/Closed',
                roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
              },
              {
                title: 'Dead Leads',
                key: 'DeadLeads',
                url: '/leads/Dead',
                roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
              },
              {
                title: 'Agencies',
                key: 'agencies',
                url: '/agencies',
                roles: ['administrator', 'super admin', 'manager'],
              },
              {
                title: 'Buyers',
                key: 'buyers',
                url: '/buyers',
                roles: ['administrator', 'super admin', 'manager'],
              },
            ],
          }
        : undefined,
      {
        title: 'Contacts',
        key: 'contacts',
        icon: 'fe fe-users',
        children: [
          {
            title: 'Import/Export',
            key: 'imports',
            url: '/contacts/imports',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Contacts List',
            key: 'contacts_list',
            url: '/contacts/list',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'DNC Contacts',
            key: 'dnc_contacts',
            url: '/contacts/dnc',
            roles: ['administrator', 'super admin'],
          },
        ],
      },
      {
        title: 'Account',
        key: 'account',
        icon: 'fe fe-user',
        children: [
          {
            title: 'Account',
            key: 'account',
            url: '/account',
            roles: ['administrator', 'super admin'],
          },
          {
            title: 'Add Balance',
            key: 'buyTokens',
            url: '/account/buy-tokens',
            roles: ['administrator', 'super admin'],
          },
          {
            title: 'Plans',
            key: 'pricingTables',
            url: '/account/plans',
            roles: ['administrator', 'super admin'],
          },
          {
            title: 'Transactions',
            key: 'transactions',
            url: '/account/transactions',
            roles: ['administrator', 'super admin'],
          },
          {
            title: 'Templates',
            key: 'contracts',
            url: '/contracts',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
      {
        title: 'Settings',
        key: 'settings',
        icon: 'fe fe-settings',
        children: [
          {
            title: 'Team',
            key: 'team',
            url: '/team',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Edit Profile',
            key: 'edit-profile',
            url: '/edit-profile',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
    ],
    manager: [
      {
        title: 'Dashboard',
        key: 'dashboardAnalytics',
        icon: 'fe fe-home',
        url: '/dashboard/analytics',
        roles: ['administrator', 'super admin', 'manager'],
      },
      {
        title: 'SMS',
        key: 'Schedules',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'Conversations',
            key: 'messaging',
            url: '/schedules/messaging',
            roles: ['administrator', 'super admin', 'manager', 'qualifications'],
          },
          {
            title: 'Campaigns',
            key: 'schedulesList',
            url: '/schedules/list',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'List Builder',
            key: 'campaign-builder',
            url: '/schedules/campaign-builder',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Schedule Campaign',
            key: 'createSchedule',
            url: '/schedules/create',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Messages',
            key: 'messages',
            url: '/messages',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Keywords',
            key: 'keywords',
            url: '/keywords',
            roles: ['administrator', 'super admin', 'manager'],
          },
        ],
      },
      {
        title: 'CRM',
        key: 'srm',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'All Leads',
            key: 'leads_contacts',
            url: '/leads',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
          {
            title: 'Assigned Leads',
            key: 'AssignedLeads',
            url: '/leads/Assigned',
          },
          {
            title: 'Follow Up Leads',
            key: 'FollowUpLeads',
            url: '/leads/Follow Up',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Offer Leads',
            key: 'OfferLeads',
            url: '/leads/Offer',
          },
          {
            title: 'Disposition Leads',
            key: 'DispositionLeads',
            url: '/leads/Disposition',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Closed Leads',
            key: 'ClosedLeads',
            url: '/leads/Closed',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Dead Leads',
            key: 'DeadLeads',
            url: '/leads/Dead',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Agencies',
            key: 'agencies',
            url: '/agencies',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Buyers',
            key: 'buyers',
            url: '/buyers',
            roles: ['administrator', 'super admin', 'manager'],
          },
        ],
      },
      {
        title: 'Contacts',
        key: 'contacts',
        icon: 'fe fe-users',
        children: [
          {
            title: 'Import/Export',
            key: 'imports',
            url: '/contacts/imports',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Contacts List',
            key: 'contacts_list',
            url: '/contacts/list',
            roles: ['administrator', 'super admin', 'manager'],
          },
        ],
      },
      {
        title: 'Account',
        key: 'account',
        icon: 'fe fe-user',
        children: [
          {
            title: 'Templates',
            key: 'contracts',
            url: '/contracts',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
      {
        title: 'Settings',
        key: 'settings',
        icon: 'fe fe-settings',
        children: [
          {
            title: 'Team',
            key: 'team',
            url: '/team',
            roles: ['administrator', 'super admin', 'manager'],
          },
          {
            title: 'Edit Profile',
            key: 'edit-profile',
            url: '/edit-profile',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
    ],
    qualifications: [
      {
        title: 'SMS',
        key: 'Schedules',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'Conversations',
            key: 'messaging',
            url: '/schedules/messaging',
            roles: ['administrator', 'super admin', 'manager', 'qualifications'],
          },
        ],
      },
      {
        title: 'CRM',
        key: 'srm',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'All Leads',
            key: 'leads_contacts',
            url: '/leads',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
          {
            title: 'Assigned Leads',
            key: 'AssignedLeads',
            url: '/leads/Assigned',
          },
          {
            title: 'Follow Up Leads',
            key: 'FollowUpLeads',
            url: '/leads/Follow Up',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Offer Leads',
            key: 'OfferLeads',
            url: '/leads/Offer',
          },
          {
            title: 'Disposition Leads',
            key: 'DispositionLeads',
            url: '/leads/Disposition',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Closed Leads',
            key: 'ClosedLeads',
            url: '/leads/Closed',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Dead Leads',
            key: 'DeadLeads',
            url: '/leads/Dead',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
        ],
      },
      {
        title: 'Account',
        key: 'account',
        icon: 'fe fe-user',
        children: [
          {
            title: 'Templates',
            key: 'contracts',
            url: '/contracts',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
      {
        title: 'Settings',
        key: 'settings',
        icon: 'fe fe-settings',
        children: [
          {
            title: 'Edit Profile',
            key: 'edit-profile',
            url: '/edit-profile',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
    ],
    acquisitions: [
      {
        title: 'CRM',
        key: 'srm',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'All Leads',
            key: 'leads_contacts',
            url: '/leads',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
          {
            title: 'Assigned Leads',
            key: 'AssignedLeads',
            url: '/leads/Assigned',
          },
          {
            title: 'Follow Up Leads',
            key: 'FollowUpLeads',
            url: '/leads/Follow Up',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Offer Leads',
            key: 'OfferLeads',
            url: '/leads/Offer',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Closed Leads',
            key: 'ClosedLeads',
            url: '/leads/Closed',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Dead Leads',
            key: 'DeadLeads',
            url: '/leads/Dead',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
        ],
      },
      {
        title: 'Account',
        key: 'account',
        icon: 'fe fe-user',
        children: [
          {
            title: 'Templates',
            key: 'contracts',
            url: '/contracts',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
      {
        title: 'Settings',
        key: 'settings',
        icon: 'fe fe-settings',
        children: [
          {
            title: 'Edit Profile',
            key: 'edit-profile',
            url: '/edit-profile',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
    ],
    dispositions: [
      {
        title: 'CRM',
        key: 'srm',
        icon: 'fe fe-message-square',
        children: [
          {
            title: 'All Leads',
            key: 'leads_contacts',
            url: '/leads',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
          {
            title: 'Disposition Leads',
            key: 'DispositionLeads',
            url: '/leads/Disposition',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
          {
            title: 'Closed Leads',
            key: 'ClosedLeads',
            url: '/leads/Closed',
            roles: ['administrator', 'super admin', 'manager', 'acquisitions', 'dispositions'],
          },
        ],
      },
      {
        title: 'Account',
        key: 'account',
        icon: 'fe fe-user',
        children: [
          {
            title: 'Templates',
            key: 'contracts',
            url: '/contracts',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
      {
        title: 'Settings',
        key: 'settings',
        icon: 'fe fe-settings',
        children: [
          {
            title: 'Edit Profile',
            key: 'edit-profile',
            url: '/edit-profile',
            roles: [
              'administrator',
              'super admin',
              'manager',
              'acquisitions',
              'dispositions',
              'qualifications',
            ],
          },
        ],
      },
    ],
  }

  // console.time('Calculating')
  // const allowed = []
  // for (let i = 0; i < menus.length; i += 1) {
  //   const item = menus[i]
  //   console.log('MenuItem', item)
  //   const children = []
  //   if (item.children && item.children.length) {
  //     for (let j = 0; j < item.children.length; j += 1) {
  //       const subItem = item.children[j]
  //       if (subItem.roles.includes(role.payload)) {
  //         children.push(subItem)
  //       }
  //     }
  //   }

  //   if (!children.length && item.roles) {
  //     if (item.roles.includes(role.payload)) {
  //       allowed.push(item)
  //     }
  //   }

  //   if (children && children.length) {
  //     item.children = children
  //     allowed.push(item)
  //   }
  //   console.log('Acc', allowed)
  // }
  // console.timeEnd('Calculating')

  return menus[role.payload]
}
