import { STRIPE_PUBLISHABLE_KEY } from 'config/constants'
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import './assets/styles/custom.css'
// app styles
import './global.scss'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'


// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const store = createStore(reducers(history), composeWithDevTools(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    {/* <StripeProvider apiKey="pk_live_b2kYJOG2Qx8MFroAmkqB3WVd00mkQhaRkO"> */}
    <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
      <Elements>
        <Router history={history} />
      </Elements>
    </StripeProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
export { store, history }
