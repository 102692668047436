const actions = {
  SET_STATE: 'coupons/SET_STATE',
  GET_COUPONS: 'coupons/GET_COUPONS',
  CREATE_COUPON: 'coupons/CREATE_COUPON',
  UPDATE_COUPON: 'coupons/UPDATE_COUPON',
  DELETE_COUPON: 'coupons/DELETE_COUPON',
  VALIDATE_COUPON: 'coupons/VALIDATE_COUPON',
}

export default actions
