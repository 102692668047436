import { all, takeEvery, put, call } from 'redux-saga/effects'

import {
  getCoupons,
  createCoupon,
  deleteCoupon,
  updateCoupon,
  validateCoupon,
} from 'services/coupons'
import { notification } from 'antd'
import actions from './actions'

export function* GET_COUPONS({ payload }) {
  yield put({
    type: 'coupons/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(getCoupons, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        list: success.results.map(coupon => {
          return {
            ...coupon,
            multiple_use: coupon.multiple_use === 'forever' ? 'Yes' : 'No',
          }
        }),
        loading: false,
      },
    })
  }
}

export function* VALIDATE_COUPON({ payload }) {
  yield put({
    type: 'coupons/SET_STATE',
    payload: {
      couponLoading: true,
    },
  })

  const success = yield call(validateCoupon, payload)
  if (success.status === 400) {
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        coupon: {},
        couponLoading: false,
        message: success.data.error,
      },
    })
  } else {
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        coupon: success,
        message:
          success.status === 'Active'
            ? `$${success.discount} discount applied.`
            : 'Coupon expired.',
        couponLoading: false,
      },
    })
  }
}

export function* CREATE_COUPON({ payload }) {
  yield put({
    type: 'coupons/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(createCoupon, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Success',
      description: success.message,
    })
    yield put({
      type: 'coupons/GET_COUPONS',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_COUPON({ payload }) {
  yield put({
    type: 'coupons/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(updateCoupon, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Success',
      description: success.message,
    })
    yield put({
      type: 'coupons/GET_COUPONS',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE_COUPON({ payload }) {
  yield put({
    type: 'coupons/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(deleteCoupon, payload)
  if (success.status === 400) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'coupons/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Success',
      description: 'Coupon successfully deleted!',
    })
    yield put({
      type: 'coupons/GET_COUPONS',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_COUPONS, GET_COUPONS),
    takeEvery(actions.VALIDATE_COUPON, VALIDATE_COUPON),
    takeEvery(actions.CREATE_COUPON, CREATE_COUPON),
    takeEvery(actions.DELETE_COUPON, DELETE_COUPON),
    takeEvery(actions.UPDATE_COUPON, UPDATE_COUPON),
  ])
}
