import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

export function getList(data) {
  return axios
    .get(`${SERVER_ADDRESS}/phoneNumbers/all${data ? `?organization=${data.organization}` : ''}`)
    .then(res => {
      console.log(res)
      return res.data
    })
}

export function getAreaCodes() {
  console.log('getAreaCodes')
  return axios.get(`${SERVER_ADDRESS}/twilio/services/area-codes`).then(res => {
    console.log('Area Codes = ', res.data.data)
    return res.data.data
  })
}

export function getTwilioNumber(code, area) {
  console.log('code,area = ', code, area)
  return axios.get(`${SERVER_ADDRESS}/twilio/services?area=${area}&code=${code}`).then(res => {
    console.log('Twilio Numbers = ', res.data.data)
    return res.data.data
  })
}

export function addNewRecord(data) {
  console.log(data, 'Service')
  return axios.post(`${SERVER_ADDRESS}/phoneNumbers/`, data).then(res => {
    console.log(res)
    return res.data
  })
}

export function buyTwilioNumber(phoneNumber) {
  return axios.post(`${SERVER_ADDRESS}/twilio/services`, { phoneNumber }).then(res => {
    console.log(res)
    return res.data.data
  })
}

export function deleteRecord(id) {
  console.log(id, 'Service')
  return axios.delete(`${SERVER_ADDRESS}/phoneNumbers/${id}`).then(res => {
    return res.data
  })
}

export function updateRecord(id, data) {
  console.log(data, 'Service')
  return axios.put(`${SERVER_ADDRESS}/phoneNumbers/${id}`, data).then(res => {
    console.log(res)
    return res.data
  })
}
