import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

export function getTransactions() {
  return axios
    .get(`${SERVER_ADDRESS}/transactions`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}

export function getAllTransactions() {
  return axios
    .get(`${SERVER_ADDRESS}/transactions/all`, {})
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
}
