import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getList,
  addNewRecord,
  buyTwilioNumber,
  deleteRecord,
  updateRecord,
  getAreaCodes,
  getTwilioNumber,
} from 'services/phoneNumbers'
import actions from './actions'

export function* GET_LIST({ payload }) {
  const success = yield call(getList, payload)
  yield put({
    type: 'phoneNumbers/SET_STATE',
    payload: {
      list: success,
    },
  })
}

export function* GET_AREA_CODES({ payload }) {
  const success = yield call(getAreaCodes, payload)
  yield put({
    type: 'phoneNumbers/SET_STATE',
    payload: {
      areaCode: success,
    },
  })
}

export function* ADD_NEW_RECORD({ payload }) {
  const success = yield call(addNewRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Added',
      description: 'You have successfully added the new record.',
    })
  }
  yield put({
    type: 'phoneNumbers/GET_LIST',
    payload: {
      organization: payload.organization,
    },
  })
}

export function* GET_TWILIO_NUMBER({ payload }) {
  const { code, area } = payload.data
  yield put({
    type: 'phoneNumbers/SET_STATE',
    payload: {
      twilioNumbers: [],
    },
  })
  const success = yield call(getTwilioNumber, code, area)

  if (success) {
    if (success.length === 0) {
      notification.warning({
        message: 'Not Found',
        description: 'Number is not available for this area code',
      })
    }
    yield put({
      type: 'phoneNumbers/SET_STATE',
      payload: {
        twilioNumbers: success,
      },
    })
  }
}

export function* BUY_TWILIO_NUMBER({ payload }) {
  const { phoneNumber } = payload
  const success = yield call(buyTwilioNumber, phoneNumber)
  if (success) {
    notification.success({
      message: 'Successfully Purchased',
      description: 'You have successfully purchased the new twilio number.',
    })
  }
  yield put({
    type: 'phoneNumbers/GET_LIST',
    payload: {
      organization: payload.organization,
    },
  })
}

export function* UPDATE_RECORD({ payload }) {
  const { id, data } = payload
  const success = yield call(updateRecord, id, data)
  if (success) {
    notification.success({
      message: 'Successfully Updated',
      description: 'You have successfully updated the  record.',
    })
  }
  yield put({
    type: 'phoneNumbers/GET_LIST',
    payload: {
      organization: data.organization,
    },
  })
}

export function* DELETE_RECORD({ payload }) {
  const { id, data } = payload
  const success = yield call(deleteRecord, id)
  if (success) {
    notification.success({
      message: 'Successfully Deleted',
      description: 'You have successfully deleted the  record.',
    })
  }
  yield put({
    type: 'phoneNumbers/GET_LIST',
    payload: {
      organization: data.organization,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_AREA_CODES, GET_AREA_CODES),
    takeEvery(actions.ADD_NEW_RECORD, ADD_NEW_RECORD),
    takeEvery(actions.BUY_TWILIO_NUMBER, BUY_TWILIO_NUMBER),
    takeEvery(actions.GET_TWILIO_NUMBER, GET_TWILIO_NUMBER),
    takeEvery(actions.DELETE_RECORD, DELETE_RECORD),
    takeEvery(actions.UPDATE_RECORD, UPDATE_RECORD),
  ])
}
