import { all, takeEvery, put, call } from 'redux-saga/effects'

import {
  getContacts,
  getCampaignList,
  importContacts,
  exportContacts,
  getSchema,
  getCounts,
  getFilters,
  deleteRecord,
  deleteImport,
  addNewRecord,
  updateRecord,
  getImportedData,
  getContactsByGroup,
  sendToSkipTraced,
  exportContactsByGroup,
  exportAllContacts,
} from 'services/contact'
import { notification } from 'antd'
import actions from './actions'

export function* GET_CONTACTS(data) {
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const {
    payload: { page, limit, filters, groupId },
  } = data
  const success = yield call(getContacts, page, limit, filters, groupId)

  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      list: success.results,
      countObj: success.countObj,
      loading: false,
      meta: {
        page,
        pageSize: 50,
        total: success.countObj.contactCount,
        pageTotal: Math.ceil(success.countObj.contactCount / 50),
      },
    },
  })
}

export function* GET_CAMPAIGN_LIST() {
  const success = yield call(getCampaignList)
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      campaignList: success,
    },
  })
}
export function* IMPORT_CONTACTS(postData) {
  yield call(importContacts, postData)
  // yield put({
  //   type: 'contacts/SET_STATE',
  //   payload: {
  //     campaignList: success
  //   },
  // })
}
export function* EXPORT_CONTACTS(postData) {
  yield call(exportContacts, postData)
}
export function* GET_SCHEMA_COLUMNS() {
  const success = yield call(getSchema)
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      schema: success,
    },
  })
}

export function* GET_COUNTS() {
  const success = yield call(getCounts)
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      countObj: success,
    },
  })
}

export function* GET_FILTERS() {
  const success = yield call(getFilters)
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      filters: success,
    },
  })
}

export function* GET_IMPORTED_DATA() {
  const success = yield call(getImportedData)
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      imports: success,
    },
  })

  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_RECORD({ payload }) {
  console.log(payload)
  const success = yield call(deleteRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Deleted',
      description: 'You have successfully deleted the  record.',
    })
    yield put({
      type: 'contacts/DELETE_STATE',
      payload: {
        id: payload,
      },
    })
    yield put({
      type: 'contacts/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SEND_TO_SKIP_TRACED({ payload }) {
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(sendToSkipTraced, payload)
  if (success.status) {
    notification.error({
      message: 'Error',
      description: success.data.message,
    })
    yield put({
      type: 'contacts/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    notification.success({
      message: 'Successfully Traced',
      description: success.message,
    })
    yield put({
      type: 'contacts/GET_CONTACTS',
      payload: {
        id: payload,
      },
    })
    yield put({
      type: 'contacts/GET_IMPORTED_DATA',
      payload: false,
      loading: false,
    })
    yield put({
      type: 'tokens/IS_ACCOUNT_EXIST',
      payload: {
        loading: false,
      },
    })
  }
}

export function* EXPORT_CONTACTS_BY_GROUP({ payload }) {
  const success = yield call(exportContactsByGroup, payload)
  if (success) {
    yield put({
      type: 'contacts/GET_CONTACTS',
      payload: {
        id: payload,
      },
    })
  }
}

export function* EXPORT_ALL_CONTACTS({ payload }) {
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(exportAllContacts, payload)
  if (success) {
    yield put({
      type: 'contacts/GET_CONTACTS',
      payload: {
        id: payload,
      },
    })
    return yield put({
      type: 'contacts/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  yield put({
    type: 'contacts/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_IMPORT({ payload }) {
  const success = yield call(deleteImport, payload)
  if (success) {
    notification.success({
      message: 'Successfully Deleted',
      description: 'You have successfully deleted the  import.',
    })
    yield put({
      type: 'contacts/DELETE_STATE',
      payload: {
        id: payload,
      },
    })
    yield put({
      type: 'contacts/GET_IMPORTED_DATA',
      payload: false,
    })
    yield put({
      type: 'contacts/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_CONTACTS_BY_GROUP({ payload }) {
  const { page } = payload
  const success = yield call(getContactsByGroup, payload)
  if (success) {
    yield put({
      type: 'contacts/SET_STATE',
      payload: {
        list: success.results,
        countObj: success.countObj,
        loading: false,
        meta: {
          page,
          pageSize: 50,
          total: success.countObj.contactCount,
          pageTotal: Math.ceil(success.countObj.contactCount / 50),
        },
      },
    })
  }
}

export function* ADD_NEW_RECORD({ payload }) {
  const success = yield call(addNewRecord, payload)
  if (success) {
    notification.success({
      message: 'Successfully Added',
      description: 'You have successfully added the new record.',
    })
    yield put({
      type: 'contacts/GET_CONTACTS',
      payload: { page: 1, limit: 50 },
    })
  }
}

export function* UPDATE_RECORD({ payload }) {
  const { id, data } = payload
  const success = yield call(updateRecord, id, data)
  if (success) {
    notification.success({
      message: 'Successfully Updated',
      description: 'You have successfully updated the  record.',
    })
    console.log('Updated Record: ', success)
    yield put({
      type: 'contacts/GET_CONTACTS',
      payload: { page: 1, limit: 50 },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CONTACTS, GET_CONTACTS),
    takeEvery(actions.GET_CAMPAIGN_LIST, GET_CAMPAIGN_LIST),
    takeEvery(actions.IMPORT_CONTACTS, IMPORT_CONTACTS),
    takeEvery(actions.EXPORT_CONTACTS, EXPORT_CONTACTS),
    takeEvery(actions.GET_SCHEMA_COLUMNS, GET_SCHEMA_COLUMNS),
    takeEvery(actions.GET_COUNTS, GET_COUNTS),
    takeEvery(actions.GET_FILTERS, GET_FILTERS),
    takeEvery(actions.DELETE_RECORD, DELETE_RECORD),
    takeEvery(actions.DELETE_IMPORT, DELETE_IMPORT),
    takeEvery(actions.ADD_NEW_RECORD, ADD_NEW_RECORD),
    takeEvery(actions.UPDATE_RECORD, UPDATE_RECORD),
    takeEvery(actions.GET_IMPORTED_DATA, GET_IMPORTED_DATA),
    takeEvery(actions.GET_CONTACTS_BY_GROUP, GET_CONTACTS_BY_GROUP),
    takeEvery(actions.SEND_TO_SKIP_TRACED, SEND_TO_SKIP_TRACED),
    takeEvery(actions.EXPORT_CONTACTS_BY_GROUP, EXPORT_CONTACTS_BY_GROUP),
    takeEvery(actions.EXPORT_ALL_CONTACTS, EXPORT_ALL_CONTACTS),
  ])
}
