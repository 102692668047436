import actions from './actions'

const INDEX_PAGE_SIZE_DEFAULT = 50

const initialState = {
  list: '',
  authorized: false,
  loading: false,
  schema: false,
  countObj: {
    contactCount: 0,
    skippedCount: 0,
  },
  filters: {},
  meta: {
    page: 0,
    pageSize: INDEX_PAGE_SIZE_DEFAULT,
    pageTotal: 1,
    total: 0,
  },
}

export default function contactsReducer(state = initialState, action) {
  console.log(action.type === 'GET_IMPORTED_DATA')
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.DELETE_STATE:
      return Object.assign({}, state, {
        list: [...state.list.filter(item => item._id !== action.payload.id)],
        meta: {
          ...state.meta,
          total: state.meta.total - 1,
        },
        countObj: {
          ...state.countObj,
          contactCount: state.countObj.contactCount - 1,
        },
      })
    case actions.UPDATE_STATE:
      console.log(
        'IN REDUCER: ',
        Object.assign({}, state, {
          list: [
            ...state.list.map(item =>
              item._id === action.payload.id ? action.payload.item : item,
            ),
          ],
          meta: {
            ...state.meta,
            total: state.meta.total - 1,
          },
          countObj: {
            ...state.countObj,
            contactCount: state.countObj.contactCount - 1,
          },
        }),
      )
      return Object.assign({}, state, {
        list: [
          ...state.list.map(item => (item._id === action.payload.id ? action.payload.item : item)),
        ],
        meta: {
          ...state.meta,
          total: state.meta.total - 1,
        },
        countObj: {
          ...state.countObj,
          contactCount: state.countObj.contactCount - 1,
        },
      })
    default:
      return state
  }
}
