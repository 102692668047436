import { notification } from 'antd'
import { SERVER_ADDRESS } from '../config/constants'

const axios = require('axios').default

// const headers = {
//   'Content-Type': 'application/x-www-form-urlencoded'
// };

export function getList(page, limit, filters) {
  const requestUrl = !filters
    ? `${SERVER_ADDRESS}/customers/all?page=${page}&limit=${limit}`
    : `${SERVER_ADDRESS}/customers/all?page=${page}&limit=${limit}&filters=${encodeURIComponent(
        JSON.stringify(filters),
      )}`
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`

  return axios.get(requestUrl).then(res => {
    return res.data
  })
}

export function addNewRecord(data) {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  return axios
    .post(`${SERVER_ADDRESS}/customers/`, data)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}

export function deleteRecord(id) {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  console.log(id, 'Service')
  return axios.delete(`${SERVER_ADDRESS}/customers/${id}`).then(res => {
    return res.data
  })
}

export function updateRecord(id, data) {
  axios.defaults.headers.common.Authorization = `${localStorage.getItem('jwtToken')}`
  console.log(data, 'Service')
  return axios
    .put(`${SERVER_ADDRESS}/customers/${id}`, data)
    .then(res => {
      console.log(res)
      return res.data
    })
    .catch(error => {
      notification.warning({
        message: error.code || error.response.status,
        description: error.response.data.error,
      })
    })
}
